import { useTranslation } from "react-i18next";
import Button from "../../../../components/button/button";
import Container from "../../../../components/container/container";
import Typography from "../../../../components/typography/typography";
import styles from "./hero.module.css";
import classNames from "classnames";
import { ReactComponent as FacebookSVG } from "../../../../svg/facebook.svg";
import { ReactComponent as YoutubeSVG } from "../../../../svg/youtube.svg";
import { ReactComponent as TelegramSVG } from "../../../../svg/telegram.svg";
import { ReactComponent as InstagramSVG } from "../../../../svg/instagram.svg";
import { Link } from "react-router-dom";

export interface IHeroSectionProps {
  facebookURL: string;
  telegramURL: string;
  youtubeURL: string;
  instagramURL: string;
}

const HeroSection = ({
  facebookURL,
  telegramURL,
  youtubeURL,
  instagramURL,
}: IHeroSectionProps) => {
  const { t } = useTranslation();

  const instruments = [
    {
      label: t("global.musicianTerms"),
      picture: "/pictures/music-note.png",
      path: "/musician-terms",
    },
    {
      disable: true,
      label: t("global.notes"),
      picture: "/pictures/notes.png",
    },
    {
      disable: true,
      label: t("global.lesson"),
      picture: "/pictures/book.png",
    },
    {
      disable: true,
      label: t("global.instruments.drumset"),
      picture: "/pictures/drumset.png",
    },
    {
      disable: true,
      label: t("global.media"),
      picture: "/pictures/media.png",
    },
    {
      disable: true,
      label: t("global.searchBands"),
      picture: "/pictures/search.png",
    },
  ];

  const renderLinks = instruments.map((item, index) => (
    <Link key={index} to={item.path || "#"}>
      <div
        style={{
          opacity: item.disable ? 0.4 : 1,
          cursor: item.disable ? "not-allowed" : "pointer",
        }}
        className={classNames([
          styles.instrument,
          (index === 1 || index === 3) && styles.margin,
        ])}
      >
        <img
          className={styles.instrumentPicture}
          src={item.picture}
          alt={item.label}
        />
        <Typography variant="span" size={16} color="green" weight={300}>
          {item.label}
        </Typography>
      </div>
    </Link>
  ));

  return (
    <>
      <section id="hero" className={styles.heroSection}>
        <Container>
          <div className={styles.sectionContent}>
            <div className={styles.leftContent}>
              <div className={styles.smallQuoteBox}>
                <Typography
                  variant="label"
                  size={11}
                  weight={400}
                  color="silver"
                  uppercase
                >
                  {t("landing.heroSection.quote")}
                </Typography>
              </div>
              <Typography
                variant="h1"
                size={48}
                weight={300}
                color="silver"
                uppercase
              >
                {t("landing.heroSection.title")}
              </Typography>
              <Typography size={16} variant="p" color="grey" weight={300}>
                {t("landing.heroSection.description")}
              </Typography>
              <div className={styles.socialButtons}>
                {facebookURL !== "" && (
                  <a rel="noreferrer" href={facebookURL} target="_blank">
                    <Button
                      size="small"
                      color="dark"
                      icon={<FacebookSVG width={20} hanging={20} />}
                    />
                  </a>
                )}
                {youtubeURL !== "" && (
                  <a rel="noreferrer" href={youtubeURL} target="_blank">
                    <Button
                      size="small"
                      color="dark"
                      icon={<YoutubeSVG width={20} hanging={20} />}
                    />
                  </a>
                )}
                {telegramURL !== "" && (
                  <a rel="noreferrer" href={telegramURL} target="_blank">
                    <Button
                      size="small"
                      color="dark"
                      icon={<TelegramSVG width={20} hanging={20} />}
                    />
                  </a>
                )}
                {instagramURL !== "" && (
                  <a rel="noreferrer" href={instagramURL} target="_blank">
                    <Button
                      size="small"
                      color="dark"
                      icon={<InstagramSVG width={20} hanging={20} />}
                    />
                  </a>
                )}
              </div>
            </div>
            <div className={styles.instruments}>{renderLinks}</div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HeroSection;
