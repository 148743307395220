import { CSSProperties, ReactNode, memo } from "react";
import styles from "./button.module.css";
import Typography from "../typography/typography";

interface IButtonProps {
  onClick?: () => void;
  children?: string | null;
  icon?: ReactNode;
  color?: "purple" | "dark" | "green";
  size?: "medium" | "small";
  disabled?: boolean;
}

const Button = ({
  children,
  onClick = () => null,
  icon,
  size = "medium",
  color = "purple",
  disabled = false,
}: IButtonProps) => {
  const style: CSSProperties = {
    height: size === "medium" ? "67px" : "49px",
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={styles.button}
    >
      <Typography
        variant="span"
        size={size === "medium" ? 16 : 14}
        weight={300}
        color="silver"
      >
        {children}
      </Typography>
      {icon}
    </button>
  );
};

export default memo(Button);
