import classNames from "classnames";
import { CSSProperties, ReactNode, memo } from "react";

interface ITypographyProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "label" | "p" | "span";
  size?: number;
  weight?: 100 | 200 | 300 | 400 | 500 | "bold";
  align?: "left" | "center" | "right";
  color?:
    | "purple"
    | "yellow"
    | "dark"
    | "grey"
    | "silver"
    | "red"
    | "white"
    | "purple2"
    | "green"
    | "black";
  uppercase?: boolean;
  children: ReactNode;
  className?: string;
}

const Typography = ({
  variant = "p",
  size = 18,
  weight = 400,
  uppercase = false,
  align = "left",
  color = "black",
  className,
  children,
}: ITypographyProps) => {
  const style: CSSProperties = {
    fontSize: `${size}px`,
    fontWeight: weight,
    textTransform: uppercase ? "uppercase" : "initial",
    color: `var(--${color}-color)`,
    textAlign: align,
  };

  const renderTypography = () => {
    switch (variant) {
      case "h1":
        return (
          <h1 className={classNames(className)} style={style}>
            {children}
          </h1>
        );

      case "h2":
        return (
          <h2 className={classNames(className)} style={style}>
            {children}
          </h2>
        );

      case "h3":
        return (
          <h3 className={classNames(className)} style={style}>
            {children}
          </h3>
        );

      case "h4":
        return (
          <h4 className={classNames(className)} style={style}>
            {children}
          </h4>
        );

      case "span":
        return (
          <span className={classNames(className)} style={style}>
            {children}
          </span>
        );

      case "label":
        return (
          <label
            className={classNames(className)}
            style={{ ...style, display: "block" }}
          >
            {children}
          </label>
        );

      default:
        return (
          <p className={classNames(className)} style={style}>
            {children}
          </p>
        );
    }
  };

  return renderTypography();
};

export default memo(Typography);
