import { useTranslation } from "react-i18next";
import Container from "../../components/container/container";
import Typography from "../../components/typography/typography";
import styles from "./musicianTerms.module.css";
import BackButton from "../../components/backButton/backButton";

const MusicianTerms = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className={styles.container}>
        <BackButton />
        <Typography variant="h1" size={48} color="silver" weight={300}>
          {t("musicianTerms.title")}
        </Typography>
        <Typography
          className={styles.description}
          size={16}
          variant="p"
          color="grey"
          weight={200}
        >
          {t("musicianTerms.description")}
        </Typography>
        <div className={styles.content}>
          <Typography variant="h1" size={32} color="silver" weight={300}>
            {t("musicianTerms.tempo.title")}
          </Typography>
          <Typography
            className={styles.description}
            size={16}
            variant="p"
            color="grey"
            weight={200}
          >
            {t("musicianTerms.tempo.description")}
          </Typography>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Термін</th>
                <th>Значення</th>
                <th>BPM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <i>adagietto</i>
                </td>
                <td>радше повільно, трохи швидше ніж adagio</td>
                <td>72-76</td>
              </tr>
              <tr>
                <td>
                  <i>adagio</i>
                </td>
                <td>повільно</td>
                <td>66-76</td>
              </tr>
              <tr>
                <td>
                  <i>allegretto</i>
                </td>
                <td>середньо швидко, трохи повільніше ніж allegro</td>
                <td>112-120</td>
              </tr>
              <tr>
                <td>
                  <i>allegrissimo</i>
                </td>
                <td>дуже швидко, швидше ніж allegro</td>
                <td>172-176</td>
              </tr>
              <tr>
                <td>
                  <i>allegro</i>
                </td>
                <td>швидко</td>
                <td>120-168</td>
              </tr>
              <tr>
                <td>
                  <i>allegro moderato</i>
                </td>
                <td>середньо швидко, трохи повільніше ніж allegro</td>
                <td>116-120</td>
              </tr>
              <tr>
                <td>
                  <i>andante</i>
                </td>
                <td>в помірному темпі, кроком</td>
                <td>76-108</td>
              </tr>
              <tr>
                <td>
                  <i>andante moderato</i>
                </td>
                <td>стриманий кроковий темп, швидше ніж andante</td>
                <td>92-112</td>
              </tr>
              <tr>
                <td>
                  <i>andantino</i>
                </td>
                <td>стриманий темп, трішки швидше аніж andante</td>
                <td>80-108</td>
              </tr>
              <tr>
                <td>
                  <i>grave</i>
                </td>
                <td>дуже повільно</td>
                <td>25-45</td>
              </tr>
              <tr>
                <td>
                  <i>larghetto</i>
                </td>
                <td>радше повільно та протяжно, трішки швидше ніж largo</td>
                <td>60-66</td>
              </tr>
              <tr>
                <td>
                  <i>larghissimo</i>
                </td>
                <td>екстремально повільно, повільніше аніж largo</td>
                <td>25 та нижче</td>
              </tr>
              <tr>
                <td>
                  <i>largo</i>
                </td>
                <td>широко, протяжно, дуже повільно</td>
                <td>40-60</td>
              </tr>
              <tr>
                <td>
                  <i>lento</i>
                </td>
                <td>повільно</td>
                <td>45-60</td>
              </tr>
              <tr>
                <td>
                  <i>moderato</i>
                </td>
                <td>помірно</td>
                <td>108-120</td>
              </tr>
              <tr>
                <td>
                  <i>prestissimo</i>
                </td>
                <td>дуже швидко, швидше аніж presto</td>
                <td>200 та вижче</td>
              </tr>
              <tr>
                <td>
                  <i>presto</i>
                </td>
                <td>дуже швидко</td>
                <td>168-200</td>
              </tr>
              <tr>
                <td>
                  <i>vivace</i>
                </td>
                <td>швидко та жваво</td>
                <td>140-176</td>
              </tr>
              <tr>
                <td>
                  <i>vivacissimo</i>
                </td>
                <td>дуже швидко та жваво, швидше аніж vivace</td>
                <td>172-176</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.content}>
          <Typography variant="h1" size={24} color="silver" weight={300}>
            {t("musicianTerms.changeTempo.title")}
          </Typography>
          <Typography
            className={styles.description}
            size={16}
            variant="p"
            color="grey"
            weight={300}
          >
            {t("musicianTerms.changeTempo.description")}
          </Typography>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Термін</th>
                <th>Скорочення</th>
                <th>Значення</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <i>a piacere</i>
                </td>
                <td></td>
                <td>за бажанням, темп заданий за бажанням виконавця</td>
              </tr>
              <tr>
                <td>
                  <i>a tempo</i>
                </td>
                <td></td>
                <td>повернутися до попереднього темпу</td>
              </tr>
              <tr>
                <td>
                  <i>rallentando</i>
                </td>
                <td>rall.</td>
                <td>поступово сповільнити</td>
              </tr>
              <tr>
                <td>
                  <i>ritardando</i>
                </td>
                <td>rit., ritard.</td>
                <td>поступово сповільнити</td>
              </tr>
              <tr>
                <td>
                  <i>ritenuto</i>
                </td>
                <td>riten.</td>
                <td>різко сповільнити</td>
              </tr>
              <tr>
                <td>
                  <i>rubato</i>
                </td>
                <td></td>
                <td>у вільному темпі, без стабільного пульсу</td>
              </tr>
              <tr>
                <td>
                  <i>stringendo</i>
                </td>
                <td></td>
                <td>поступово пришвидшити</td>
              </tr>
              <tr>
                <td>
                  <i>accelerando</i>
                </td>
                <td>accel.</td>
                <td>поступово пришвидшити</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.content}>
          <Typography variant="h1" size={32} color="silver" weight={300}>
            {t("musicianTerms.dynamics.title")}
          </Typography>
          <Typography
            className={styles.description}
            size={16}
            variant="p"
            color="grey"
            weight={300}
          >
            {t("musicianTerms.dynamics.description")}
          </Typography>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Термін</th>
                <th>Символ</th>
                <th>Значення</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <i>pianississimo</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>ppp</strong>
                    </i>
                  </span>
                </td>
                <td>вкрай тихо</td>
              </tr>
              <tr>
                <td>
                  <i>pianissimo</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>pp</strong>
                    </i>
                  </span>
                </td>
                <td>дуже тихо</td>
              </tr>
              <tr>
                <td>
                  <i>piano</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>p</strong>
                    </i>
                  </span>
                </td>
                <td>тихо</td>
              </tr>
              <tr>
                <td>
                  <i>mezzo piano</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>mp</strong>
                    </i>
                  </span>
                </td>
                <td>помірно тихо</td>
              </tr>
              <tr>
                <td>
                  <i>mezzo forte</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>mf</strong>
                    </i>
                  </span>
                </td>
                <td>помірно голосно</td>
              </tr>
              <tr>
                <td>
                  <i>forte</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>f</strong>
                    </i>
                  </span>
                </td>
                <td>голосно</td>
              </tr>
              <tr>
                <td>
                  <i>fortissimo</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>ff</strong>
                    </i>
                  </span>
                </td>
                <td>дуже голосно</td>
              </tr>
              <tr>
                <td>
                  <i>fortississimo</i>
                </td>
                <td>
                  <span>
                    <i>
                      <strong>fff</strong>
                    </i>
                  </span>
                </td>
                <td>вкрай голосно</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default MusicianTerms;
