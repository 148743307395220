import { createBrowserRouter } from "react-router-dom";
import { Root } from "./root";
import LandingPage from "../pages/landing/landing";
import { routes } from "../constants/routes";
import MusicianTerms from "../pages/musicianTerms/musicianTerms";

export const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: routes.main,
        element: <LandingPage />,
      },
      {
        path: routes.misicianTerms,
        element: <MusicianTerms />,
      },
    ],
  },
]);
