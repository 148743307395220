import styles from "./footer.module.css";
import { ReactComponent as LogoSVG } from "../../svg/logoWithoutTitle.svg";
import { Entrypoint } from "../../types/Entrypoint";

interface IFooter {
  entrypoint: Entrypoint | null;
}

const Footer = ({ entrypoint }: IFooter) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.divider} />
      <LogoSVG width={45} height={45} />
    </footer>
  );
};

export default Footer;
