import HeroSection from "./sections/hero/hero";
import { Entrypoint } from "../../types/Entrypoint";
import { useOutletContext } from "react-router-dom";
import Advertising from "./sections/advertising/advertising";

const LandingPage = () => {
  const { entrypoint }: { entrypoint: Entrypoint } = useOutletContext();

  return (
    <div className="sections">
      <HeroSection
        instagramURL={entrypoint?.about.instagramLink || ""}
        youtubeURL={entrypoint?.about.youtubeLink || ""}
        telegramURL={entrypoint?.about.telegramLink || ""}
        facebookURL={entrypoint?.about.facebookLink || ""}
      />
      <Advertising />
    </div>
  );
};

export default LandingPage;
