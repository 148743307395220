import Container from "../../../../components/container/container";
import Typography from "../../../../components/typography/typography";
import styles from "./advertising.module.css";

const Advertising = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Typography
          uppercase
          size={28}
          weight={300}
          color="silver"
          variant="h1"
        >
          Оголошення
        </Typography>
        <div className={styles.list}>
          <div className={styles.box}>
            <img
              src="/banners/8sound.png"
              alt="8Sound"
              className={styles.picture}
            />
            <div className={styles.information}>
              <Typography size={20} weight={300} color="silver" variant="h1">
                8Sound - музичний простір для кожного, хто бажає займатися
                творчістю 🎵
              </Typography>
              <Typography variant="p" size={16} color="grey" weight={300}>
                🤍 Заняття з вокалу, фортепіано та гітари. Тут, як вдома 🥰
                <br /> <br />
                м.Кривий Ріг, вул. Свято-Миколаївська 43
              </Typography>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Advertising;
