// import { NavLink } from "react-router-dom";
import styles from "./header.module.css";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";
import Typography from "../typography/typography";
import { ReactComponent as HumburgerSVG } from "../../svg/hamburger.svg";
import { ReactComponent as CloseSVG } from "../../svg/close.svg";
import classNames from "classnames";

const NavLinks = () => {
  const { t } = useTranslation();
  const [isShowMobileMenu, setShowMobileMenu] = useState(false);
  const [isActiveSection, setActiveSection] = useState("home");

  useEffect(() => {
    onScrollSpy();
  }, []);

  const onScrollSpy = () => {
    const sections = document.querySelectorAll(".sections section");

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries)
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
      },
      {
        rootMargin: "-50% 0px",
      }
    );
    for (let i = 0; i < sections.length; i++) observer.observe(sections[i]);
  };

  useEffect(() => {
    document.body.style.overflow = isShowMobileMenu ? "hidden" : "auto";
  }, [isShowMobileMenu]);

  const links = [
    {
      sectionId: "hero",
      path: routes.main,
      label: t("global.navigation.main"),
    },
    {
      sectionId: "forum",
      path: routes.news,
      label: t("global.navigation.forum"),
    },
    {
      sectionId: "metronome",
      path: routes.resources,
      label: t("global.navigation.metronome"),
    },
  ];

  const toggleMobileMenu = useCallback(
    () => setShowMobileMenu(!isShowMobileMenu),
    [isShowMobileMenu]
  );

  const scrollTo = (id: string) => {
    const section = document.getElementById(id);

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const renderLinks = links?.map((item) => (
    <div
      onClick={() => scrollTo(item.sectionId)}
      className={classNames([
        isActiveSection === item.sectionId && styles.activeLink,
      ])}
    >
      <Typography
        size={14}
        weight={400}
        uppercase
        color="silver"
        variant="span"
      >
        {item.label}
      </Typography>
    </div>
  ));

  const renderMobileMenu = (
    <div
      className={classNames(styles.mobileNavLinks, {
        [styles.isShowMobileMenu]: isShowMobileMenu,
      })}
    >
      <div className={styles.closeButton} onClick={toggleMobileMenu}>
        <CloseSVG />
      </div>
      {renderLinks}
    </div>
  );

  return (
    <>
      <div className={styles.navLinks}>{renderLinks}</div>
      <div className={styles.humburgerButton}>
        <HumburgerSVG onClick={toggleMobileMenu} />
        {renderMobileMenu}
      </div>
    </>
  );
};

export default memo(NavLinks);
