import styles from "./backButton.module.css";
import { ReactComponent as ArrowLeftSVG } from "../../svg/arrow-small-left.svg";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button className={styles.backButton} onClick={() => navigate(-1)}>
      <ArrowLeftSVG color="white" />
    </button>
  );
};

export default BackButton;
