import { memo } from "react";
import { ReactComponent as LogoSVG } from "../../svg/logo.svg";
import styles from "./header.module.css";
import Container from "../container/container";
import NavLinks from "./navLinks";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

const Header = () => {
  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.headerContent}>
          <Link to={routes.main} className={styles.logo}>
            <LogoSVG width={158} height={74} />
          </Link>
          <NavLinks />
        </div>
      </Container>
    </header>
  );
};

export default memo(Header);
